import CustomRoutes from "./router/custom-routes";
import './i18n';



function App() {
  return (
    <>
      
          <CustomRoutes />
    </>
  );
}

export default App;
